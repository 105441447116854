<template>
  <div
    class="modal fade show"
    v-if="modalVisible"
  >
    <div class='modal__backdrop'></div>
    <div class="modal-dialog modal-xl zindex-2 modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header bg-light">
          <h5 class="modal-title" id="exampleModalLabel">
            # {{ withdraw.id || '-' }} · {{ $t('f_management.withdraw_details_modal_title') }}
          </h5>
          <button type="button" class="close" @click="closeModal()">
            <i aria-hidden="true" class="ki ki-close font-size-h4"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row d-flex flex-wrap px-2">
            <div class="col-md-6 col-sm-12">
              <div class="row">
                <p class="col-md-4 col-sm-12">{{ $t('commons.name') }} {{ $t('commons.surname') }}:</p>
                <b class="col-md-8 col-sm-12">{{ userFullName }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('c_management.identity_no') }}:</p>
                <b class="col-md-8 col-sm-12">{{ user.identity_number || '-' }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{$t('commons.cost') }}:</p>
                <b class="col-md-8 col-sm-12">{{ withdraw.amount_formatted || '-' }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('commons.status') }}:</p>
                <b class="col-md-8 col-sm-12">
                    <span class="badge" :class="statusClass">
                        {{ statusLabel }}
                    </span>
                </b>
              </div>
              <div class="row mt-2" v-if="+withdraw.status === 40">
                <p class="col-md-4 col-sm-12">{{$t('f_management.reason_for_cancellation') }}:</p>
                <b class="col-md-8 col-sm-12">{{ withdraw.reason || '-' }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{$t('f_management.date_request') }}:</p>
                <b class="col-md-8 col-sm-12">{{ withdraw.created_at }}</b>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="row">
                <p class="col-md-4 col-sm-12">{{ $t('f_management.transaction_number') }}:</p>
                <b class="col-md-8 col-sm-12">{{ withdraw.code || '-' }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('f_management.payment_method') }}:</p>
                <b class="col-md-8 col-sm-12 text-break">{{ paymentMethod }}</b>
              </div>
              <div class="row mt-2" v-if="!defaultCurrency.crypto">
                <p class="col-md-4 col-sm-12">{{ $t('finance.bank') }}:</p>
                <b class="col-md-8 col-sm-12">{{ provider.bank || '-' }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ defaultCurrency.crypto ? $t('finance.wallet_address') : 'IBAN' }}:</p>
                <b class="col-md-8 col-sm-12">{{ wallet.address || '-' }}</b>
              </div>
              <template v-if="defaultCurrency.crypto">
                <div class="row mt-2">
                  <p class="col-md-4 col-sm-12">{{ $t('service.error') }}:</p>
                  <b class="col-md-8 col-sm-12">{{ withdraw.error || '-' }}</b>
                </div>
                <div class="row mt-2">
                  <p class="col-md-4 col-sm-12">{{ $t('merchant.txid') }}:</p>
                  <b class="col-md-8 col-sm-12">{{ withdraw.txid || '-' }}</b>
                </div>
                <div class="row mt-2">
                  <p class="col-md-4 col-sm-12">Fireblocks Is:</p>
                  <b class="col-md-8 col-sm-12">{{ withdraw.fireblocks_id || '-' }}</b>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <template v-if="isPendingOperationApproval && checkPermission('crm.finance.withdraw.modal.action')">
            <input
              class="form-control col-md-4 col-sm-12"
              type="text"
              :placeholder="$t('f_management.btn_external_approval_desc')"
              :title="$t('f_management.btn_external_approval_desc')"
              v-model="financeModalActionForm.txid"
            />
            <button type="button" class="btn btn-success font-weight-bold" @click.prevent="approveDeclineProcess('1')">
              {{ $t('merchant.btn_approve') }}
            </button>
            <button type="button" class="btn btn-outline-success font-weight-bold" @click.prevent="approveDeclineFinance('1', 'external')" :title="$t('f_management.btn_external_approval')" :disabled="!financeModalActionForm.txid">
              <span class="text-truncate">{{ $t('f_management.btn_external_approval') }}</span>
            </button>
            <button type="button" class="btn btn-danger font-weight-bold" @click.prevent="approveDeclineProcess('2')">
              {{ $t('merchant.btn_decline') }}
            </button>
          </template>

          <template v-if="isPendingFinancialApproval && checkPermission('crm.finance.withdraw.finance-modal.action')">
            <button type="button" class="btn btn-success font-weight-bold" @click.prevent="approveDeclineFinance('1', '')">
              {{ $t('merchant.btn_approve') }}
            </button>
            <button type="button" class="btn btn-danger font-weight-bold" @click.prevent="approveDeclineFinance('2', '')">
              {{ $t('merchant.btn_decline') }}
            </button>
          </template>
          <button
            type="button"
            class="btn btn-outline-secondary"
            @click="closeModal()"
            data-dismiss="modal"
          >
            {{ $t('merchant.btn_close') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {},
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapState({
      permissions: (state) => state.customer.permissions,
      modalVisible: (state) => state.withdrawTransaction.detailsModalVisible,
      withdraw: (state) => state.withdrawTransaction.detailsModalData.withdraw,
      defaultCurrency: (state) => state.withdrawTransaction.detailsModalData.defaultCurrency,
      financeModalActionForm: state => state.withdrawTransaction.financeModalActionForm,
    }),
    user() {
        return this.withdraw?.user || {};
    },
    userFullName() {
        const { name, surname } = this.user;
        if(name && surname) return `${name} ${surname}`
        return '-';
    },
    paymentMethod() {
        if(this.provider?.provider) {
            return this.$t(`f_management.payment_methods.${this.provider.provider}`)
        }
        return '-';
    },
    statusClass() {
        switch (this.withdraw?.status) {
            case 10:
                return 'badge-warning';
            case 20:
            case 70:
                return 'badge-light';
            case 30:
                return 'badge-success';
            case 40:
            case 50:
            case 60:
                return 'badge-danger';
            default:
                return '';
        }
    },
    statusLabel() {
        if(this.withdraw?.status) {
            return this.$t(`f_management.statuses.${this.withdraw.status}`)
        }
        return '-'
    },
    isPendingOperationApproval() {
        return (+this.withdraw?.status === 10) || false
    },
    isPendingFinancialApproval() {
        return (+this.withdraw?.status === 60) || false
    },
    wallet() {
      return this.withdraw?.wallet || {};
    },
    provider() {
      return this.withdraw?.provider || {};
    }
  },
  methods: {
    closeModal() {
      this.$store.commit('withdrawTransaction/TOGGLE_DETAILS_MODAL', false);
    },
    approveDeclineProcess(action) {
      this.$store.dispatch('withdrawTransaction/DETAILS_MODAL_SMS', { id: this.withdraw.id, action })
    },
    approveDeclineFinance(action, confirmType) {
      this.$store.dispatch('withdrawTransaction/FINANCE_SMS', { id: this.withdraw.id, action, confirmType })
    },
    checkPermission( permission) {
      let status = false;
      this.permissions?.forEach(function (item) {
        if (item.name == permission) {
          status = true;
        }
      });
      return status
    },
  },
  created() { },
};
</script>
<style scoped>
  .show {
    display: block;
  }
  .modal__backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
</style>